<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="设备编号">
                <a-input v-model="queryParam.devCode" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="水表编号">
                <a-input v-model="queryParam.devSn" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="handleExport" v-hasPermi="['wisdom:devicesettleday:export']">
          <a-icon type="download" />导出
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :typeOptions="dict.type['sys_yes_no']"
        @ok="getList"
      />
      <!-- 详情 -->
      <info
        ref="info"
        :typeOptions="dict.type['sys_yes_no']"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :scroll="{ x: 2000 }"
        :bordered="tableBordered">
        <span slot="userInfo" slot-scope="text, record">
          <div class="spanBox">
            <span>{{ record.userName }}</span>
            <span>{{ record.userPhone }}</span>
          </div>
        </span>
        <span slot="sfSettle" slot-scope="text, record">
          <a-tag color="orange" v-if="record.sfSettle == 0">未结算</a-tag>
          <a-tag color="green" v-else-if="record.sfSettle == 1">已结算</a-tag>
        </span>
        <span slot="status" slot-scope="text, record">
          <a-tag color="orange" v-if="record.status == 0">关</a-tag>
          <a-tag color="blue" v-else-if="record.status == 1">开</a-tag>
        </span>
        <span slot="reportingTime" slot-scope="text, record">
          {{ parseTime(record.reportingTime) }}
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>

import { deviceSettleDayPage } from '@/api/pages/statistics'
import { tableMixin } from '@/store/table-mixin'
import CreateForm from './modules/CreateForm'
import Info from './modules/info'

export default {
  name: 'Config',
  mixins: [tableMixin],
  dicts: ['sys_yes_no'],
  components: {
    CreateForm, Info
  },
  data () {
    return {
      list: [],
      loading: false,
      total: 0,
      // 日期范围
      dateRange: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        devCode: null,
        devSn: null
      },
      columns: [
        {
          title: '设备名称',
          dataIndex: 'devName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '设备编号',
          dataIndex: 'devCode',
          ellipsis: true,
          align: 'center',
          width: 150
        },
        {
          title: '水表编号',
          dataIndex: 'devSn',
          ellipsis: true,
          align: 'center',
          width: 150
        },
        {
          title: '口径',
          dataIndex: 'caliber',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '绑定人信息',
          dataIndex: 'userInfo',
          scopedSlots: { customRender: 'userInfo' },
          width: 180,
          align: 'center'
        },
        {
          title: '上报类型',
          dataIndex: 'reportType',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '上报时间',
          dataIndex: 'reportingTime',
          scopedSlots: { customRender: 'reportingTime' },
          ellipsis: true,
          width: 160,
          align: 'center'
        },
        {
          title: '总用水量',
          dataIndex: 'totalWater',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '上月用水量',
          dataIndex: 'cycleWaterMonth',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '当期用量',
          dataIndex: 'cycleWater',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '结算金额',
        //   dataIndex: 'payMoney',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '余额',
          dataIndex: 'balance',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '阀门状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        }
        // {
        //   title: '结算状态',
        //   dataIndex: 'sfSettle',
        //   scopedSlots: { customRender: 'sfSettle' },
        //   ellipsis: true,
        //   align: 'center'
        // }
      ]
    }
  },
  filters: {
  },
  created () {
    console.log(this.$route.query)
    this.queryParam.devCode = this.$route.query.devCode ? this.$route.query.devCode : null
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询参数列表 */
    getList () {
      this.loading = true
      deviceSettleDayPage(this.queryParam).then(response => {
          this.list = response.data.records
          this.total = response.data.total
          this.loading = false
        }
      )
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.dateRange = []
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        devCode: null,
        devSn: null
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '提示',
        content: '是否确认导出?',
        onOk () {
          that.download('wisdom/wisdom-device-settle-day/export', {
            ...that.queryParam
          }, `抄收统计_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>

<style scoped>
.spanBox{
  display: flex;
  flex-direction: column;
}
</style>
